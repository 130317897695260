import { cn } from '~/utils/misc.ts'

export function Logo({ className }: { className?: string }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 200 192"
			className={cn('fill-current', className)}
		>
			<path
				fill-rule="evenodd"
				d="M128.943 144v-6.579c7.013 4.174 15.181 6.579 23.899 6.579C178.845 144 200 122.681 200 96.4754c0-26.2037-21.155-47.5214-47.158-47.5214-8.718 0-16.886 2.4054-23.899 6.5792V48.954c0-7.96 1.921-13.7091 5.873-17.5754 6.631-6.4866 18.5-6.2371 18.608-6.2261l.009-25.1227c-.25-.0125-.736-.0298-1.42-.0298-5.178 0-21.74 1.009-34.305 13.0862C108.612 21.8276 104 33.8955 104 48.954V144h24.943Zm0-55.778c2.89-8.4731 10.086-14.9299 18.944-16.717a.395.395 0 0 0 .042-.0079c.738-.1475 1.484-.2652 2.244-.3452a7.409 7.409 0 0 0 .168-.0211c.071-.0094.142-.0189.215-.026.606-.0549 1.217-.0816 1.834-.0925.096-.0028.191-.0097.286-.0167.123-.0089.245-.0179.37-.0179 14.076 0 25.488 11.5015 25.488 25.686 0 14.1863-11.412 25.6863-25.488 25.6863-14.077 0-25.489-11.5-25.489-25.6863 0-2.9075.502-5.6926 1.386-8.2989v-.1428ZM71.0568 48v6.5792C64.0441 50.4054 55.8761 48 47.1584 48 21.155 48 0 69.3192 0 95.5246c0 26.2034 21.155 47.5214 47.1584 47.5214 8.7177 0 16.8857-2.405 23.8984-6.579v6.579c0 7.96-1.9213 13.709-5.873 17.575-6.6312 6.487-18.5018 6.237-18.6077 6.227l-.0093 25.122c.2507.013.7364.03 1.42.03 5.1786 0 21.7404-1.009 34.3069-13.086C91.3882 170.172 96 158.105 96 143.046V48H71.0568Zm0 55.778c-2.8897 8.473-10.0862 14.93-18.944 16.717-.014.003-.028.005-.042.008-.738.147-1.4838.265-2.2437.345-.0561.006-.1119.014-.1679.021-.0712.01-.1427.019-.2151.026-.6057.055-1.2176.082-1.8341.093-.096.002-.1911.009-.2862.016h-.0001c-.1224.009-.2448.018-.3692.018-14.0769 0-25.4881-11.501-25.4881-25.6857 0-14.1862 11.4112-25.686 25.4881-25.686 14.0768 0 25.4881 11.4998 25.4881 25.686 0 2.9075-.5014 5.6927-1.3858 8.2987v.143Z"
				clip-rule="evenodd"
			/>
		</svg>
	)
}
